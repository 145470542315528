@import url("https://use.typekit.net/dvr7shz.css");


:root {
    --main-dark: #1a202c;
    --main-gray: #657dae;
    --main-gray-light: #a0aec0;
    --main-light: #FAFAFA;
}

.text-section_body {
    list-style-type: none;
}

.background_dark {
    color: var(--main-light);
    background: var(--main-dark) !important;
}

.background_light {
    color: var(--main-dark);
    background: var(--main-light) !important;
}

.text_dark {
    color: var(--main-dark) !important;
}

.text_light {
    color: var(--main-light) !important;
}

.caption {
    font-style: italic;
}

.image-full-fitted {
    width: 70vh;
}

.card {
    color: var(--main-dark);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 0 !important;
}

.card_media {
    position: relative;
    float: left;
}

.card_title {
    color: var(--main-dark);
    font-size: medium !important;
    position: absolute;
    top: 2%;
    left: 4%;
}

.nav-bar_item {
    color: inherit;
    padding: 0px 20px;
    text-decoration: none;
}


/* .nav-bar {} */

.main {
    background: inherit;
}

.footer_box {
    padding: 7vh;
}

.footer_text {
    text-align: center;
    color: var(--main-gray);
}

.hero_box {
    color: inherit;
    padding-top: 8%;
    padding-bottom: 6%;
    text-align: left;
}

.hero_title {
  font-family: monotalic, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.text-image-section_container {
    color: inherit;
}


/* Grow Shadow */

.hvr-grow-shadow {
    -webkit-transform: perspective(1px) translateZ(0) !important;
    transform: perspective(1px) translateZ(0) !important;
    -webkit-transition-duration: 0.3s !important;
    transition-duration: 0.3s !important;
    -webkit-transition-property: box-shadow, transform !important;
    transition-property: box-shadow, transform !important;
}

.hvr-grow-shadow:hover,
.hvr-grow-shadow:focus,
.hvr-grow-shadow:active {
    -webkit-transform: scale(1.009) !important;
    transform: scale(1.009) !important;
}


/* fontawesome icons */

.icon {
    color: var(--main-gray-light);
    transition: color 1s;
    margin-right: 10px;
}

.icon:hover {
    color: var(--main-light);
}
